import { useEffect } from 'react';
import Image1 from "../../assets/patchImgs/patch-bear.png";
import Image2 from "../../assets/patchImgs/patch-cat.png";
import Image3 from "../../assets/patchImgs/patch-dog.png";
import Image4 from "../../assets/patchImgs/patch-duck.png";
import Image5 from "../../assets/patchImgs/patch-racoon.png";
import Image6 from "../../assets/patchImgs/patch-unicorn.png";
import hamburger from "../../assets/hamburger.png"

const patchImages = [
  Image1, Image2, Image3, Image4, Image5, Image6
];
let cursorImageNextIndex = 0;

const CursorImage = () => {
  useEffect(() => {
    const handleClick = (event) => {
      const headerElement = document.querySelector('Header'); 
      if (headerElement && headerElement.contains(event.target)) {
        return;
      }

      const buttonElement = document.querySelector('.connect-with-x'); 
      if (buttonElement && buttonElement.contains(event.target)) {
        return;
      }

      const imageElement = document.querySelector('.patch-images'); 
      if (imageElement && imageElement.contains(event.target)) {
        return;
      }

      const arenaImage1 = document.querySelector('.arena-image1'); 
      if (arenaImage1 && arenaImage1.contains(event.target)) {
        return;
      }

      const arenaImage2 = document.querySelector('.arena-image2'); 
      if (arenaImage2 && arenaImage2.contains(event.target)) {
        return;
      }

      const videoElement = document.querySelector('.arena-video-element '); 
      if (videoElement && videoElement.contains(event.target)) {
        return;
      }

      const connectSocialfi = document.querySelector('.connect-button-socialfi'); 
      if (connectSocialfi && connectSocialfi.contains(event.target)) {
        return;
      }

      const ponchiqsLogo = document.querySelector('.ponchiqs-logo'); 
      if (ponchiqsLogo && ponchiqsLogo.contains(event.target)) {
        return;
      }

      const ponchiqsSocialfiLogo = document.querySelector('.ponchiqs-socialfi-logo'); 
      if (ponchiqsSocialfiLogo && ponchiqsSocialfiLogo.contains(event.target)) {
        return;
      }

      const socialfiPopup = document.querySelector('.socialfi-popup'); 
      if (socialfiPopup && socialfiPopup.contains(event.target)) {
        return;
      }

      const signinPonchiqsLogo = document.querySelector('.signin-ponchiqs-logo'); 
      if (signinPonchiqsLogo && signinPonchiqsLogo.contains(event.target)) {
        return;
      }

      const signinPopup = document.querySelector('.signin-popup'); 
      if (signinPopup && signinPopup.contains(event.target)) {
        return;
      }

      const connectXSignin = document.querySelector('.connect-x-signin'); 
      if (connectXSignin && connectXSignin.contains(event.target)) {
        return;
      }

      const emailPopup = document.querySelector('.email-popup'); 
      if (emailPopup && emailPopup.contains(event.target)) {
        return;
      }

      const ponchiqsLogoEmail = document.querySelector('.ponchiqs-logo-email'); 
      if (ponchiqsLogoEmail && ponchiqsLogoEmail.contains(event.target)) {
        return;
      }

      const authPopup = document.querySelector('.auth-popup'); 
      if (authPopup && authPopup.contains(event.target)) {
        return;
      }

      const authText = document.querySelector('.auth-text'); 
      if (authText && authText.contains(event.target)) {
        return;
      }

      const authButton = document.querySelector('.authorize-button'); 
      if (authButton && authButton.contains(event.target)) {
        return;
      }

      const enterWorldButton = document.querySelector('.enter-world-button'); 
      if (enterWorldButton && enterWorldButton.contains(event.target)) {
        return;
      }

      const footerSection = document.querySelector('.footer-section'); 
      if (footerSection && footerSection.contains(event.target)) {
        return;
      }

      const hamburger = document.querySelector('.HAMBURGER-ICON'); 
      if ( hamburger  &&  hamburger.contains(event.target)) {
        return;
      }

      const pointsSection = document.querySelector('.points-section'); 
      if ( pointsSection  && pointsSection.contains(event.target)) {
        return;
      }

      const pointsButtons = document.querySelector('.social-points-button'); 
      if (  pointsButtons &&  pointsButtons.contains(event.target)) {
        return;
      }

      const ponchiqsPassButton = document.querySelector('.ponchiqs-pass-button'); 
      if (ponchiqsPassButton && ponchiqsPassButton.contains(event.target)) {
        return;
      }

      const ponchiqsPointsButton = document.querySelector('.ponchiqs-points-button'); 
      if (ponchiqsPointsButton && ponchiqsPointsButton.contains(event.target)) {
        return;
      }

      const referralButton = document.querySelector('.referral-button'); 
      if (referralButton && referralButton.contains(event.target)) {
        return;
      }

      const tierWheelButton = document.querySelector('.tier-wheel-button'); 
      if (tierWheelButton && tierWheelButton.contains(event.target)) {
        return;
      }

      const bonusButton = document.querySelector('.bonus-button'); 
      if (bonusButton && bonusButton.contains(event.target)) {
        return;
      }
      
      const tierBonusSection = document.querySelector('.tier-bonus-section'); 
      if (tierBonusSection && tierBonusSection.contains(event.target)) {
        return;
      }

      const section2 = document.getElementById('section2'); 
      if (section2 && section2.contains(event.target)) {
        return;
      }

      const piggy = document.querySelector('.piggyIMG'); 
      if (piggy && piggy.contains(event.target)) {
        return;
      }

      const toybearIMG = document.querySelector('.toybearIMG'); 
      if (toybearIMG && toybearIMG.contains(event.target)) {
        return;
      }

      const RecoonIMG = document.querySelector('.RecoonIMG'); 
      if (RecoonIMG && RecoonIMG.contains(event.target)) {
        return;
      }

      const chickyIMG = document.querySelector('.chickyIMG'); 
      if (chickyIMG && chickyIMG.contains(event.target)) {
        return;
      }

      
      
      const cursorImage = document.createElement("img");
      cursorImage.alt = "cursor image";
      cursorImage.classList.add("cursor-image");
      cursorImage.style.display = "none";
      cursorImage.src = patchImages[cursorImageNextIndex];
      cursorImageNextIndex = (cursorImageNextIndex + 1) % patchImages.length;
      cursorImage.style.position = "absolute";
      cursorImage.style.left = event.pageX - 140 + "px";
      cursorImage.style.top = event.pageY - 140 + "px";
      cursorImage.style.display = "block";
      document.body.appendChild(cursorImage);

      setTimeout(() => {
        cursorImage.style.animation = "transformOut 1s";
        setTimeout(() => {
          document.body.removeChild(cursorImage);
        }, 1000);
      }, 2000);
    };

    document.addEventListener("click", handleClick);
    
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return null;
};

export default CursorImage;