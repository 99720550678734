import React, { useEffect } from 'react';
import cloudImage1 from '../../assets/bg-cloud-1.png';
import cloudImage2 from '../../assets/bg-cloud-2.png';
import cloudImage3 from '../../assets/bg-cloud-3.png';
import Ziper from '../../assets/zeppelin.webp';

const SkyBackground = () => {
  useEffect(() => {
    const sky = document.getElementById('sky-background');
    const numClouds = 14;

    function createCloud() {
      let isZeppelin = Math.random() < 0.1;
      const cloud = document.createElement('div');
      cloud.classList.add('cloud');

      const cloudImages = [cloudImage1, cloudImage2, cloudImage3];
      const randomImageIndex = Math.floor(Math.random() * cloudImages.length);
      const imageName = cloudImages[randomImageIndex];
      cloud.style.backgroundImage = `url(${imageName})`;

      const cloudSize = Math.random() * 500 + 50;
      cloud.style.width = cloudSize + 'px';
      cloud.style.height = cloudSize * 0.7 + 'px';
      cloud.style.zIndex = Math.floor(cloudSize).toString();
      cloud.style.opacity = (Math.random() * 0.7 + 0.3).toString();

      const duration = Math.random() * 10 + 20;
      cloud.style.animationDuration = duration + 's';
      cloud.style.animationDirection = Math.random() < 0.5 ? 'normal' : 'reverse';

      cloud.style.left = Math.random() * 100 - 100 + '%';
      cloud.style.top = Math.random() * 100 + 'vh'; // Adjusted to cover entire screen height

      sky.appendChild(cloud);

      if (isZeppelin) {
        const zeppelin = document.createElement('div');
        zeppelin.classList.add('zeppelin-container');

        const zeppelinImg = document.createElement('img');
        zeppelinImg.src = Ziper;
        zeppelinImg.alt = 'zeppelin image';
        zeppelinImg.classList.add('zeppelin');
        zeppelinImg.style.width = cloudSize + 'px';
        zeppelinImg.style.height = cloudSize * 0.8 + 'px';

        if (cloudSize < 200) {
          zeppelinImg.style.filter = 'brightness(1.2)';
          zeppelinImg.style.filter = 'blur(1px)';
        }

        zeppelin.appendChild(zeppelinImg);
        sky.appendChild(zeppelin);

        zeppelin.style.left = Math.random() * 100 - 100 + '%';
        zeppelin.style.top = Math.random() * 100 + 'vh'; // Adjusted to cover entire screen height

        zeppelin.style.animationDuration = duration + 's';

        zeppelin.addEventListener('animationend', () => {
          sky.removeChild(zeppelin);
        });
      }

      cloud.addEventListener('animationend', () => {
        sky.removeChild(cloud);
        createCloud();
      });
    }

    // Create the clouds
    for (let i = 0; i < numClouds; i++) {
      createCloud();
    }
  }, []);

  return (
    <div id="sky-background" style={{ position: 'fixed', width: '100%', height: '100vh' }}>
      {/* Background will be created here */}
    </div>
  );
};

export default SkyBackground;