// import React, { useState } from 'react';
// import { Link, NavLink, useLocation } from 'react-router-dom';
// import ponchLOGO from "../../assets/logo-wstroke.png";

// function Header() {
//   const [isNavOpen, setIsNavOpen] = useState(false);
//   const [activeSection, setActiveSection] = useState('');
//   const location = useLocation();


//   const toggleMenu = () => {
//     setIsNavOpen(!isNavOpen);
//   };



//   const handleScroll = (sectionId) => {
//     document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
//     window.history.pushState(null, null, ' '); // This clears the hash without refreshing the page
//     setIsNavOpen(false);
//     setActiveSection(sectionId);
//   };


//   const activeTabClass = 'bg-homeleman text-black  ';
//   const activeTabClass1 = 'bg-homeleman text-black w-screen text-center  ';
//   const defaultTabClass = 'text-white';

//   return (
//     <header className="fixed top-0 w-full p-5 bg-transparent flex justify-start gap-20 items-center z-50 transition duration-500 ease-in-out 3xs:gap-0 3xs:flex 3xs:justify-between 3xs:items-center xs:gap-0 xs:flex xs:justify-between xs:items-center lg:gap-24">
//       <div className="w-[20%] p-2 bg-transparent pl-[100px] 3xs:pl-0 xs:pl-0 sm:pl-0 md:pl-0 md-1:pl-0 lg:pl-[100px]">
//         <Link to="/">
//           <img
//             src={ponchLOGO}
//             alt="Logo"
//             className="w-[105px] h-full"
//           />
//         </Link>
//       </div>
//       <div className="flex flex-row justify-between pr-[100px] 3xs:justify-end 3xs:pr-0 xs:pr-0 sm:pr-0 md:pr-0 md-1:pr-0 lg:pr-[100px]">
//         <div className="flex justify-between items-center flex-row-reverse gap-3 lg:flex lg:flex-row-reverse lg:gap-10 xl:flex xl:flex-row-reverse 1xl:flex-row 2xl:flex-row">
//           <nav>
//             <section className="MOBILE-MENU flex 1xl:hidden">
//               <div
//                 className="HAMBURGER-ICON space-y-2"
//                 onClick={toggleMenu}
//               >
//                 {isNavOpen ? (
//                   <svg
//                     className="h-8 w-8 text-homeleman"
//                     viewBox="0 0 24 24"
//                     fill="none"
//                     stroke="currentColor"
//                     strokeWidth="2"
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                   >
//                     <line x1="18" y1="6" x2="6" y2="18" />
//                     <line x1="6" y1="6" x2="18" y2="18" />
//                   </svg>
//                 ) : (
//                   <>
//                     <span className="block h-1 w-8 animate-pulse bg-homeleman"></span>
//                     <span className="block h-1 w-8 animate-pulse bg-homeleman"></span>
//                     <span className="block h-1 w-8 animate-pulse bg-homeleman"></span>
//                   </>
//                 )}
//               </div>

//               <div className={isNavOpen ? 'showMenuNav' : 'hideMenuNav'}>
//                 <div
//                   className="absolute top-0 right-0 px-8 py-8"
//                   onClick={() => setIsNavOpen(false)}
//                 ></div>
//                 <ul className="flex flex-col items-center justify-start mt-5 min-h-[250px]">
//                 <li className={`text-[32px] mt-4 uppercase p-1 font-bouncy rounded-md ${activeSection === 'section1' ? activeTabClass1 : defaultTabClass}`}>
//               <button onClick={() => handleScroll('section1')}>Home</button>
//             </li>
//             <li className={`text-[32px] mt-4 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section2' ? activeTabClass1 : defaultTabClass}`}>
//               <button onClick={() => handleScroll('section2')}>P/C</button>
//             </li>
//               <li className={`text-[32px] mt-4 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section3' ? activeTabClass1 : defaultTabClass}`}>
//                 {/* <a href="#section3">CHARACTERS</a> */}  <button onClick={() => handleScroll('section3')}>CHARACTERS</button>
//               </li>
//               <li className={`text-[32px] mt-4 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section5' ? activeTabClass1 : defaultTabClass}`}>
//                 {/* <a href="#section4">GAME</a> */}  <button onClick={() => handleScroll('section5')}>GAME</button>
//               </li>
//               <li className={`text-[32px] mt-4 uppercase font-bouncy p-1 rounded-md ${location.pathname === '/socialfi' ? activeTabClass1 : defaultTabClass}`}>
//                 <NavLink to="/socialfi">SocialFi</NavLink>
//               </li>
//               <li className={`text-[32px] mt-4 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section6' ? activeTabClass1 : defaultTabClass}`}>
//                 {/* <a href="#section6">BLOG</a> */}  <button onClick={() => handleScroll('section6')}>BLOG</button>
//               </li>
//               <li className={`text-[32px] mt-4 uppercase font-bouncy p-1 rounded-sm ${activeSection === 'section7' ? activeTabClass1 : defaultTabClass}`}>
//                 <a href="#">FOOTER</a>  
//                  {/* <button onClick={() => handleScroll('section7')}>FOOTER</button> */}
//               </li>
//                 </ul>
//               </div>
//             </section>
//             <ul className="DESKTOP-MENU hidden space-x-8 1xl:flex">
//             <li className={`text-[32px] my-8 uppercase p-1 font-bouncy rounded-md ${activeSection === 'section1' ? activeTabClass : defaultTabClass}`}>
//               <button onClick={() => handleScroll('section1')}>Home</button>
//             </li>
//             <li className={`text-[32px] my-8 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section2' ? activeTabClass : defaultTabClass}`}>
//               <button onClick={() => handleScroll('section2')}>P/C</button>
//             </li>
//               <li className={`text-[32px] my-8 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section3' ? activeTabClass : defaultTabClass}`}>
//                 {/* <a href="#section3">CHARACTERS</a> */}  <button onClick={() => handleScroll('section3')}>CHARACTERS</button>
//               </li>
//               <li className={`text-[32px] my-8 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section5' ? activeTabClass : defaultTabClass}`}>
//                 {/* <a href="#section4">GAME</a> */}  <button onClick={() => handleScroll('section5')}>GAME</button>
//               </li>
//               <li className={`text-[32px] my-8 uppercase font-bouncy p-1 rounded-md ${location.pathname === '/socialfi' ? activeTabClass : defaultTabClass}`}>
//                 <NavLink to="/socialfi">SocialFi</NavLink>
//               </li>
//               <li className={`text-[32px] my-8 uppercase font-bouncy p-1 rounded-md ${activeSection === 'section6' ? activeTabClass : defaultTabClass}`}>
//                 {/* <a href="#section6">BLOG</a> */}  <button onClick={() => handleScroll('section6')}>BLOG</button>
//               </li>
//               <li className={`text-[32px] my-8 uppercase font-bouncy p-1 rounded-sm ${activeSection === 'section7' ? activeTabClass : defaultTabClass}`}>
//                 <a href="#">FOOTER</a>  
//                  {/* <button onClick={() => handleScroll('section7')}>FOOTER</button> */}
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;



import React, { useState,useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import ponchLOGO from "../../assets/logo-wstroke.png";

function Header({ activeSection, onSectionChange }) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleScroll = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    window.history.pushState(null, null, ' ');
    setIsNavOpen(false);
    onSectionChange(sectionId);
  };

  const activeTabClass = 'text-stroke-lemon text-center ';
  const activeTabClass1 = 'text-stroke-lemon text-center';
  const defaultTabClass = 'text-stroke';

  return (

    <header className="fixed top-0 w-full p-5  h-24 bg-black bg-opacity-20 flex justify-start gap-20 items-center z-50 transition duration-500 ease-in-out 3xs:gap-0 3xs:flex 3xs:justify-between 3xs:items-center xs:gap-0 xs:flex xs:justify-between xs:items-center lg:gap-24">
      <div className="w-[20%] p-2 bg-transparent pl-[100px] 3xs:pl-0 xs:pl-0 sm:pl-0 md:pl-0 md-1:pl-0 lg:pl-[100px]">
        <Link to="/">
          <img
            src={ponchLOGO}
            alt="Logo"
            className="w-[105px] h-full"
          />
        </Link>
      </div>
      <div className="flex flex-row justify-between pr-[100px] 3xs:justify-end 3xs:pr-0 xs:pr-0 sm:pr-0 md:pr-0 md-1:pr-0 lg:pr-[100px]">
        <div className="flex justify-between items-center flex-row-reverse gap-3 lg:flex lg:flex-row-reverse lg:gap-10 xl:flex xl:flex-row-reverse 1xl:flex-row 2xl:flex-row">
          <nav>
            <section className="MOBILE-MENU flex 1xl:hidden">
              <div
                className="HAMBURGER-ICON space-y-2 z-50 relative"
                onClick={toggleMenu}
              >
                {isNavOpen ? (
                  <svg
                    className="h-8 w-8 text-homeleman"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                ) : (
                  <>
                    <span className="block h-1 w-8 animate-pulse bg-homeleman"></span>
                    <span className="block h-1 w-8 animate-pulse bg-homeleman"></span>
                    <span className="block h-1 w-8 animate-pulse bg-homeleman"></span>
                    </>
                )}
              </div>

              <div className={isNavOpen ? 'showMenuNav' : 'hideMenuNav'}>
                <div
                  className="absolute top-0 right-0 px-8 py-8"
                  onClick={() => setIsNavOpen(false)}
                ></div>
                <ul className="flex flex-col items-center justify-start mt-5 min-h-[250px]">
                  <li className={`text-[32px] mt-4 uppercase p-1 font-LuckiestGuy rounded-md ${activeSection === 'section1' ? activeTabClass1 : defaultTabClass}`}>
                    <button onClick={() => handleScroll('section1')}>Home</button>
                  </li>
                  <li className={`text-[32px] mt-4 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section2' ? activeTabClass1 : defaultTabClass}`}>
                    <button onClick={() => handleScroll('section2')}>P/C</button>
                  </li>
                  <li className={`text-[32px] mt-4 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section3' ? activeTabClass1 : defaultTabClass}`}>
                    <button onClick={() => handleScroll('section3')}>CHARACTERS</button>
                  </li>
                  <li className={`text-[32px] mt-4 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section5' ? activeTabClass1 : defaultTabClass}`}>
                    <button onClick={() => handleScroll('section5')}>GAME</button>
                  </li>
                  <li className={`text-[32px] mt-4 uppercase font-LuckiestGuy p-1 rounded-md ${location.pathname === '/socialfi' ? activeTabClass1 : defaultTabClass}`}>
                    <NavLink to="/socialfi" className={({ isActive }) => (isActive ? activeTabClass : defaultTabClass)}>
                      SocialFi
                    </NavLink>
                  </li>
                  <li className={`text-[32px] mt-4 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section6' ? activeTabClass1 : defaultTabClass}`}>
                    <button onClick={() => handleScroll('section6')}>BLOG</button>
                  </li>
                  <li className={`text-[32px] mt-4 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section7' ? activeTabClass1 : defaultTabClass}`}>
                    {/* <a href="#">FOOTER</a> */}
                    <button onClick={() => handleScroll('section7')}>FOOTER</button>
                  </li>
                </ul>
              </div>
            </section>
            <ul className="DESKTOP-MENU hidden space-x-8 1xl:flex">
              <li className={`text-[32px] my-8 uppercase p-1 font-LuckiestGuy rounded-md ${activeSection === 'section1' ? activeTabClass : defaultTabClass}`}>
                <button onClick={() => handleScroll('section1')}>Home</button>
              </li>
              <li className={`text-[32px] my-8 uppercase p-1 font-LuckiestGuy rounded-md ${activeSection === 'section2' ? activeTabClass : defaultTabClass}`}>
                <button onClick={() => handleScroll('section2')}>P/C</button>
              </li>
              <li className={`text-[32px] my-8 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section3' ? activeTabClass : defaultTabClass}`}>
                <button onClick={() => handleScroll('section3')}>CHARACTERS</button>
              </li>
              <li className={`text-[32px] my-8 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section4' ? activeTabClass : defaultTabClass}`}>
                <button onClick={() => handleScroll('section4')}>GAME</button>
              </li>
              <li className={`text-[32px] my-8 uppercase font-LuckiestGuy p-1 rounded-md ${location.pathname === '/socialfi' ? activeTabClass : defaultTabClass}`}>
                <NavLink to="/socialfi" className={({ isActive }) => (isActive ? activeTabClass : defaultTabClass)}>
                  SocialFi
                </NavLink>
              </li>
              <li className={`text-[32px] my-8 uppercase font-LuckiestGuy p-1 rounded-md ${activeSection === 'section5' ? activeTabClass : defaultTabClass}`}>
                <button onClick={() => handleScroll('section5')}>BLOG</button>
              </li>
              <li className={`text-[32px] my-8 uppercase font-LuckiestGuy p-1 rounded-sm ${activeSection === 'section6' ? activeTabClass : defaultTabClass}`}>
                <button onClick={() => handleScroll('section6')}>FOOTER</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
